import { useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  medicineDropdown,
  useFormField,
  feedingDropdown,
  headerColorStyles,
  medicineUnits,
  feedingUnits,
  feedingFoodForm,
  MODAL_DIALOG_STATE,
} from './utils';
import { useTranslation } from 'react-i18next';
import ProductDialog from './components/ProductDialog';
import { getAxiosWithToken } from '../../../utils/webApi';
import navigationMethods from '../../../utils/navigationUtils';
import CategoryIcon from './components/CategoryIcon';
import CategoryItemIconWrapper from './components/CategoryItemIconWrapper';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { MULTI_VALUE_MEASUREMENT_SUBTYPES } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import EditComponent from './components/EditComponent';
import { IUserCategoryItem } from './IUserCategoryItem.interface';
import { UPDATE_CUSTOM_PRODUCTS } from '../../../actions/actionTypes';

export type SubType = 'medicine' | 'feeding';

export interface IMultiValueMeasurement {
  subType: SubType;
}

export type SelectChangeEvent<T = string> =
  | (Event & { target: { value: T; name: string } })
  | React.ChangeEvent<HTMLInputElement>;

const MultiValueMeasurement: React.FC<IMultiValueMeasurement> = ({
  subType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeList, userCategoryItems } = useSelector(
    (store: any) => store.feedingReducer
  );

  const { input: inputProduct } = useFormField('product');
  const { input: inputQuantity } = useFormField('productQuantity');
  const { input: totalQuantity } = useFormField('totalQuantity');
  const { input: inputUnit } = useFormField('symbol');
  const { input: totalInputUnit } = useFormField('totalSymbol');
  const { input: totalProduct } = useFormField('totalProduct');
  const { input: productForm } = useFormField('productForm');

  const [selectedProduct, setSelectedProducts] = useState<string[]>([]);

  const [modalDialogState, setModalDialogState] = useState(
    MODAL_DIALOG_STATE.CLOSED
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');

  const [userAddedProductsMap, setUserAddedProductsMap] = useState<
    Map<string, IUserCategoryItem>
  >(new Map());
  const [currentCustomProductSelected, setCurrentCustomProductSelected] =
    useState<IUserCategoryItem | undefined>();

  useEffect(() => {
    loadCategoryItems();
  }, []);

  useEffect(() => {
    setSelectedProducts([]);
  }, [changeList]);

  const handleChange = (
    event: SelectChangeEvent<string[]> | SelectChangeEvent<string>
  ) => {
    event.preventDefault();
    const inputValue = event.target.value;
    if (Array.isArray(inputValue)) {
      setSelectedProducts(inputValue);
      totalProduct.onChange(event);
    } else if (inputValue) {
      const category = inputValue.split(':')[1];

      if (inputValue.includes(t('trackers:trackingScreen:add-icon-text'))) {
        setSelectedCategoryId(category);
        //open add screen
        setModalDialogState(MODAL_DIALOG_STATE.ADD);
      } else {
        inputProduct.onChange(event);
      }
    }
  };

  useEffect(() => {
    // Handle the custom product (if seleted)
    if (subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE) {
      let splittedProduct = inputProduct.value.split(':')[0];
      setCurrentCustomProductSelected(
        userAddedProductsMap.get(splittedProduct)
      );
    } else if (subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING) {
      setCurrentCustomProductSelected(
        userAddedProductsMap.get(inputProduct.value)
      );
    }
  }, [inputProduct]);

  const handleRenderValue = (selected: string[]) => {
    return selected
      .map((item) => {
        if (item) {
          // Check if the element is a custom product
          if (item.includes(':')) {
            const productName = item.split(':')[0];
            return t(`trackers:trackingScreen:${subType}.${productName}`);
          } else {
            return item;
          }
        }
      })
      .join(', ');
  };

  const loadCategoryItems = async () => {
    const response = await getAxiosWithToken().get('categoryitem/products');
    const returnedData = response.data;
    if (returnedData.success && returnedData.data.length != 0) {
      const userAddedItems: IUserCategoryItem[] = returnedData.data;

      dispatch({ type: UPDATE_CUSTOM_PRODUCTS, payload: userAddedItems });
      const productNameToProduct = new Map(
        userAddedItems
          .filter(
            (product) =>
              product.productCategoryItem.includes(subType) &&
              product.productName.trim() != ''
          )
          .map((product) => [product.productName, product])
      );

      setUserAddedProductsMap(productNameToProduct);
    } else {
      dispatch({ type: UPDATE_CUSTOM_PRODUCTS, payload: [] });
      setUserAddedProductsMap(new Map());
    }
    setCurrentCustomProductSelected(undefined);
  };

  const dropdownOption = useMemo(() => {
    if (subType == MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE) {
      return medicineDropdown;
    } else {
      return feedingDropdown;
    }
  }, [subType]);

  const units = useMemo(() => {
    if (subType == MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE) {
      return medicineUnits;
    } else {
      return feedingUnits;
    }
  }, [subType]);

  const dropdown: any = useMemo(() => {
    return dropdownOption.map((multivalueObject, multivalueIndex) => {
      const style = multivalueObject.color ?? headerColorStyles[2];
      return multivalueObject.categories.map(
        (categoryObject, categoryIndex) => {
          return [
            <MenuItem key={`label-${multivalueIndex}`} style={style} disabled>
              {t(
                `trackers:trackingScreen:${subType}.${multivalueObject.name}.category-name-${categoryIndex}.label`
              )}
            </MenuItem>,
            categoryObject.categoryOptions.map((option) => {
              if (subType == MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING) {
                return (
                  <MenuItem
                    key={option.path}
                    value={
                      `${multivalueObject.name}.${categoryObject.categoryName}.${option.path}` +
                      ':' +
                      `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`
                    }
                  >
                    <Checkbox
                      sx={{ marginLeft: 2.3 }}
                      checked={selectedProduct.includes(
                        `${multivalueObject.name}.${categoryObject.categoryName}.${option.path}` +
                          ':' +
                          `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`
                      )}
                    />
                    <ListItemText
                      primary={
                        <CategoryItemIconWrapper>
                          <CategoryIcon
                            src={navigationMethods.getSystemImageUrl(
                              `${subType}.${multivalueObject.name}.${categoryObject.categoryName}.${option.path}${option.imgType}`
                            )}
                            hasImage={option.hasImage}
                            subType={subType}
                            systemType={'system'}
                            imageType={'option'}
                          />

                          {t(
                            `trackers:trackingScreen:${subType}.${multivalueObject.name}.${categoryObject.categoryName}.${option.path}`
                          )}
                        </CategoryItemIconWrapper>
                      }
                    />
                  </MenuItem>
                );
              }
              if (subType == MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE) {
                return (
                  <MenuItem
                    key={option.path}
                    value={
                      `${multivalueObject.name}.${categoryObject.categoryName}.${option.path}` +
                      ':' +
                      `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`
                    }
                  >
                    <CategoryItemIconWrapper>
                      <CategoryIcon
                        src={navigationMethods.getSystemImageUrl(
                          `${subType}.${multivalueObject.name}.${categoryObject.categoryName}.${option.path}${option.imgType}`
                        )}
                        hasImage={option.hasImage}
                        subType={subType}
                        systemType={'system'}
                        imageType={'option'}
                      />
                      {t(
                        `trackers:trackingScreen:${subType}.${multivalueObject.name}.${categoryObject.categoryName}.${option.path}`
                      )}
                    </CategoryItemIconWrapper>
                  </MenuItem>
                );
              }
            }),
            userCategoryItems?.map(
              (
                userCategoryItem: IUserCategoryItem,
                userCategoryIndex: number
              ) => {
                let userCategoryItemCodeBase =
                  `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`.replace(
                    /-/g,
                    '.'
                  );

                let userProduct = userCategoryItem.productCategoryItem.replace(
                  /-/g,
                  '.'
                );

                if (userProduct == userCategoryItemCodeBase) {
                  return subType ==
                    MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE ? (
                    <MenuItem
                      key={`${userCategoryItem.productName}-${userCategoryIndex}`}
                      value={
                        userCategoryItem.productName +
                        ':' +
                        `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`
                      }
                    >
                      <CategoryItemIconWrapper>
                        <CategoryIcon
                          src={navigationMethods.getUserImageUrl(
                            userCategoryItem.photoThumbnail
                          )}
                          hasImage={true}
                          subType={subType}
                          systemType={'user'}
                          imageType={'option'}
                        />
                        {userCategoryItem.productName}
                      </CategoryItemIconWrapper>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={`option-${userCategoryIndex}`}
                      value={`${userCategoryItem.productName}`}
                    >
                      <Checkbox
                        sx={{ marginLeft: 2.3 }}
                        checked={selectedProduct.includes(
                          `${userCategoryItem.productName}`
                        )}
                      />
                      <ListItemText
                        primary={
                          <CategoryItemIconWrapper>
                            <CategoryIcon
                              src={navigationMethods.getUserImageUrl(
                                userCategoryItem.photoThumbnail
                              )}
                              hasImage={true}
                              subType={subType}
                              systemType={'user'}
                              imageType={'option'}
                            />
                            {userCategoryItem.productName}
                          </CategoryItemIconWrapper>
                        }
                      />
                    </MenuItem>
                  );
                } else return '';
              }
            ),
            subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE && (
              <MenuItem
                value={
                  t('trackers:trackingScreen:add-icon-text') +
                  ':' +
                  `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`
                }
              >
                <CategoryItemIconWrapper>
                  <CategoryIcon
                    src={navigationMethods.getSystemImageUrl(
                      'add-icon-option.svg'
                    )}
                    hasImage={true}
                    subType={subType}
                    systemType={'system'}
                    imageType={'addbutton'}
                  />
                  {t('trackers:trackingScreen:add-icon-text')}
                </CategoryItemIconWrapper>
              </MenuItem>
            ),
          ];
        }
      );
    });
  }, [selectedProduct, userCategoryItems]);

  const dropdownNewFood = useMemo(() => {
    return (
      subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING &&
      dropdownOption.map((multivalueObject, multivalueIndex) => {
        const style = multivalueObject.color ?? headerColorStyles[2];
        return multivalueObject.categories.map(
          (categoryObject, categoryIndex) => {
            return [
              <MenuItem key={`label-${multivalueIndex}`} style={style} disabled>
                {t(
                  `trackers:trackingScreen:${subType}.${multivalueObject.name}.category-name-${categoryIndex}.label`
                )}
              </MenuItem>,
              categoryObject.categoryOptions.map((option) => {
                return (
                  <MenuItem
                    key={option.path}
                    value={
                      `${multivalueObject.name}.${categoryObject.categoryName}.${option.path}` +
                      ':' +
                      `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`
                    }
                  >
                    <CategoryItemIconWrapper>
                      <CategoryIcon
                        src={navigationMethods.getSystemImageUrl(
                          `${subType}.${multivalueObject.name}.${categoryObject.categoryName}.${option.path}${option.imgType}`
                        )}
                        hasImage={option.hasImage}
                        subType={subType}
                        systemType={'system'}
                        imageType={'option'}
                      />

                      {t(
                        `trackers:trackingScreen:${subType}.${multivalueObject.name}.${categoryObject.categoryName}.${option.path}`
                      )}
                    </CategoryItemIconWrapper>
                  </MenuItem>
                );
              }),
              userCategoryItems?.map(
                (
                  userCategoryItem: IUserCategoryItem,
                  userCategoryIndex: number
                ) => {
                  let userCategoryItemCodeBase =
                    `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`.replace(
                      /-/g,
                      '.'
                    );
                  let userProduct =
                    userCategoryItem.productCategoryItem.replace(/-/g, '.');

                  if (userProduct == userCategoryItemCodeBase) {
                    return (
                      <MenuItem
                        key={`option-${userCategoryIndex}`}
                        value={`${userCategoryItem.productName}`}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <CategoryItemIconWrapper>
                          <CategoryIcon
                            src={navigationMethods.getUserImageUrl(
                              userCategoryItem.photoThumbnail
                            )}
                            hasImage={true}
                            subType={subType}
                            systemType={'user'}
                            imageType={'option'}
                          />
                          {userCategoryItem.productName}
                        </CategoryItemIconWrapper>
                      </MenuItem>
                    );
                  }
                }
              ),
              <MenuItem
                value={
                  t('trackers:trackingScreen:add-icon-text') +
                  ':' +
                  `${subType}.${multivalueObject.name}.${categoryObject.categoryName}`
                }
              >
                <CategoryItemIconWrapper>
                  <CategoryIcon
                    src={navigationMethods.getSystemImageUrl(
                      'add-icon-option.svg'
                    )}
                    hasImage={true}
                    subType={subType}
                    systemType={'system'}
                    imageType={'addbutton'}
                  />
                  {t('trackers:trackingScreen:add-icon-text')}
                </CategoryItemIconWrapper>
              </MenuItem>,
            ];
          }
        );
      })
    );
  }, [selectedProduct, userCategoryItems]);

  return (
    <div>
      <Stack
        direction={
          subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE
            ? { xs: 'column', sm: 'row' }
            : 'column'
        }
        paddingTop={2}
        spacing={2}
        width={'100%'}
      >
        {/* Products select dropdown (shared)*/}
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
          width={'100%'}
        >
          <FormControl fullWidth>
            <InputLabel id="main-product-checkbox-label">
              {t(
                `trackers:trackingScreen:${subType}.main-product-checkbox-label`
              )}
            </InputLabel>
            <Select
              fullWidth
              id="main-product-checkbox"
              multiple={subType == MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING}
              value={
                subType == MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING
                  ? selectedProduct
                  : inputProduct.value
              }
              sx={{ minWidth: '175px' }}
              input={
                <OutlinedInput
                  label={t(
                    `trackers:trackingScreen:${subType}.main-product-checkbox-label`
                  )}
                />
              }
              onChange={handleChange}
              renderValue={
                subType == MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING
                  ? handleRenderValue
                  : undefined
              }
            >
              {dropdown}
            </Select>
          </FormControl>
          {subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE && (
            <EditComponent
              customProductId={currentCustomProductSelected?.id}
              setModalDialogState={setModalDialogState}
            />
          )}
        </Stack>
        {/* Quantity and unit*/}
        {subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING && (
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            spacing={2}
            width={'100%'}
          >
            <FormControl fullWidth>
              <TextField
                type="number"
                label={t('trackers:trackingScreen:units.total-quantity')}
                InputProps={{
                  inputProps: { min: 0, step: 0.1 },
                }}
                value={totalQuantity.value}
                onChange={totalQuantity.onChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="unit-select-label-total">
                {t('trackers:trackingScreen:units.unit-select-label')}
              </InputLabel>
              <Select
                labelId="unit-select-label-total"
                id="unit-simple-select-total"
                value={totalInputUnit.value}
                label={t('trackers:trackingScreen:units.unit-select-label')}
                onChange={totalInputUnit.onChange}
              >
                {units.map((option, index) => {
                  return (
                    <MenuItem
                      key={t(`${option}`) + index + '-total'}
                      value={option.split('.')[1]}
                    >
                      {t(`${option}`)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        )}
        {/* New Food - dropdown */}
        {subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING && (
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
            width={'100%'}
          >
            <FormControl fullWidth>
              <InputLabel id="feeding-product-select-new-product">
                {t(`trackers:trackingScreen:${subType}.added-new-product`)}
              </InputLabel>
              <Select
                fullWidth
                id="feeding-product-select-new-product"
                value={inputProduct.value}
                sx={{ minWidth: '175px' }}
                input={
                  <OutlinedInput
                    label={t(
                      `trackers:trackingScreen:${subType}.added-new-product`
                    )}
                  />
                }
                onChange={handleChange}
              >
                {dropdownNewFood}
              </Select>
            </FormControl>
            <EditComponent
              customProductId={currentCustomProductSelected?.id}
              setModalDialogState={setModalDialogState}
            />
          </Stack>
        )}
        {/* New food - quantity, unit */}
        <Stack direction={{ xs: 'row', sm: 'row' }} spacing={2} width={'100%'}>
          <FormControl fullWidth>
            <TextField
              type="number"
              label={t('trackers:trackingScreen:units.quantity')}
              InputProps={{
                inputProps: { min: 0, step: 0.1 },
              }}
              value={inputQuantity.value}
              onChange={inputQuantity.onChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="unit-select-label">
              {t('trackers:trackingScreen:units.unit-select-label')}
            </InputLabel>
            <Select
              labelId="unit-select-label"
              id="unit-simple-select"
              value={inputUnit.value}
              label={t('trackers:trackingScreen:units.unit-select-label')}
              onChange={inputUnit.onChange}
            >
              {units.map((option, index) => {
                return (
                  <MenuItem
                    key={t(`${option}`) + index}
                    value={option.split('.')[1]}
                  >
                    {t(`${option}`)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        {/* Food type */}
        {subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING && (
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            spacing={2}
            width={'100%'}
          >
            <FormControl fullWidth>
              <InputLabel id="feeding-product-form">
                {t('trackers:trackingScreen:foodForm.label')}
              </InputLabel>
              <Select
                fullWidth
                id="feeding-product-form"
                value={productForm.value}
                sx={{ minWidth: '175px' }}
                input={
                  <OutlinedInput
                    label={t(
                      `trackers:trackingScreen:${subType}.added-new-product`
                    )}
                  />
                }
                onChange={productForm.onChange}
              >
                {feedingFoodForm.map((option, index) => {
                  return (
                    <MenuItem
                      key={t(`${option}`) + index + '-form'}
                      value={option.split('.')[1]}
                    >
                      {t(`${option}`)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        )}
      </Stack>
      <ProductDialog
        modalDialogState={modalDialogState}
        setModalDialogState={setModalDialogState}
        selectedCategoryId={selectedCategoryId}
        loadCategoryItems={loadCategoryItems}
        subType={subType}
        currentCustomProductSelected={currentCustomProductSelected}
      />
    </div>
  );
};

export default MultiValueMeasurement;
