import { Typography } from '@mui/material';
import * as i18n from 'i18next';
import moment from 'moment';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteTrackingRecord } from '../../actions/trackingRecordsActions';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import navigationMethods from '../../utils/navigationUtils';
import formatMeasurement from '../../utils/trackerMeasurement';
import DateDivider from './DateDivider';
import TrackerHistoryItem from './TrackerHistoryItem';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

// TODO Same code like in TrackerHistory
const TrackerTotalHistory = ({ historyRecords = [], trackers = [] }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const HistoryTime = ({ record }) => {
    return (
      <div>
        <p className="date">{moment(record.date).format('HH:mm')}</p>
      </div>
    );
  };

  const HistoryIndicator = ({ record }) => {
    return (
      <div className="indicator pr-2 pl-2">
        <img
          src={navigationMethods.getSystemImageUrl(record.smallImage)}
          alt="record icon"
          with="24px"
          height="24px"
        />
      </div>
    );
  };

  const HistoryDetails = ({ record }) => {
    return (
      <div>
        <Typography variant="body2" component={'span'}>{`${i18n.t(
          `trackers:trackingScreen:trackers.${record.name}`
        )}`}</Typography>
        <Typography component={'span'}>
          &nbsp;
          {formatMeasurement(
            record.quantity,
            record.symbol,
            record.type,
            record.subType,
            record.additionalInfo,
            true
          )}
        </Typography>
      </div>
    );
  };

  const handleEdit = (id, subTypeId) => {
    history.push(`${RELATIVE_PAGE_PATHS.TRACKING}/${subTypeId}/${id}`);
  };

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteTrackingRecord(id));
    },
    [dispatch]
  );

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  useEffect(() => {
    cache.current.clearAll(true);
  }, [historyRecords]);

  return (
    <div style={{ width: '100%' }}>
      <Typography sx={{ marginTop: 2, textAlign: 'center' }}>
        {i18n.t('trackers:breastfeedingScreen:history')}
      </Typography>
      <div className="history" style={{ height: '85vh' }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              rowCount={historyRecords.length}
              rowHeight={cache.current.rowHeight}
              deferredMeasurementCache={cache.current}
              rowRenderer={({ key, index, style, parent }) => (
                <CellMeasurer
                  key={key}
                  cache={cache.current}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  <div style={style}>
                    <DateDivider
                      current={historyRecords[index]}
                      previous={historyRecords[index - 1]}
                    />
                    <TrackerHistoryItem
                      record={historyRecords[index]}
                      onDelete={handleDelete}
                      onEdit={handleEdit}
                      disableEdit={true}
                      hideEdit={true}
                    >
                      <HistoryTime record={historyRecords[index]} />
                      <HistoryIndicator record={historyRecords[index]} />
                      <HistoryDetails record={historyRecords[index]} />
                    </TrackerHistoryItem>
                  </div>
                </CellMeasurer>
              )}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default TrackerTotalHistory;
