import { useState, useEffect } from 'react';
import { Col, Container } from 'reactstrap';
import LocalNavigationComponent from '../LocalNavigationComponent';
import InfoScreen from './InfoScreen';
import CredentialsScreen from './CredentialsScreen';
import { connect } from 'react-redux';
import {
  DEFAULT_LOCALE_CODE,
  FORMS,
  LANGUAGE_CODE_KEY,
  USER_REGISTRATION_DISABLED,
} from '../../utils/constants';
import { getFormValues } from 'redux-form';
import FamilyScreen from './FamilyScreen';
import ChildInfoScreen from './ChildInfoScreen';
import ChildBirthScreen from './ChildBirthScreen';
import TermsAndConditionsScreen from './TermsAndConditionsScreen';
import {
  navigateToHomeScreen,
  registerUser,
  showErrorModal,
  showInfoModal,
} from '../../actions';
import { scrollToTop } from '../../utils/commonUtils';
import navigationMethods from '../../utils/navigationUtils';
import LanguageSelector from '../LanguageSelector';
import i18next from '../../i18next';
import { languages } from '../../i18next';

const RegisterScreen = ({
  formValues,
  registerUser,
  showInfoModal,
  navigateToHomeScreen,
  showErrorModal,
}) => {
  const defaultLanguageCode = DEFAULT_LOCALE_CODE;

  let currentLanguageCode =
    localStorage.getItem(LANGUAGE_CODE_KEY) ?? defaultLanguageCode;

  currentLanguageCode = currentLanguageCode.includes('-')
    ? currentLanguageCode.split('-')[0]
    : currentLanguageCode;

  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [page, setPage] = useState(1);
  const [childBornInfoShowed, setChildBornInfoShowed] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [language, setLanguage] = useState(currentLanguage.code);

  useEffect(() => {
    if (USER_REGISTRATION_DISABLED) {
      showInfoModal(
        i18next.t(
          'userRegisterScreenActions:registerScreen:disabledRegistration'
        )
      );
      navigationMethods.goBack();
    }
    scrollToTop();
  }, [showInfoModal]);

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  const onSubmit = () => {
    const values = { ...formValues, languageCode: language };
    values.isChildGirl = !!values.isChildGirl;

    if (captchaValue === null && page === 6) {
      const captchaError = i18next.t(
        'userRegisterScreenActions:registerScreen:captchaError'
      );
      showErrorModal(captchaError);
    } else {
      registerUser(values);
    }
  };

  const nextPage = () => {
    const values = { ...formValues };

    if (
      values.isChildBorn4WeeksBeforeDueDate &&
      !childBornInfoShowed &&
      page === 5
    ) {
      const message = i18next.t(
        'userRegisterScreenActions:registerScreen:childBirthScreen.birthInfoCalculation'
      );
      showInfoModal(message);
      setChildBornInfoShowed(true);
    } else {
      setPage(page + 1);
    }
  };

  const previousPage = () => {
    const values = { ...formValues };

    setPage(page - 1);

    if (!values.isChildBorn4WeeksBeforeDueDate) {
      setChildBornInfoShowed(false);
    }
  };

  const validateCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const renderInfoScreen = () => {
    if (page === 1) {
      return (
        <InfoScreen
          onSubmit={nextPage}
          currentPage={page}
          language={language}
        />
      );
    }
  };

  const renderCredentialsScreen = () => {
    if (page === 2) {
      return (
        <CredentialsScreen
          previousPage={previousPage}
          onSubmit={nextPage}
          currentPage={page}
        />
      );
    }
  };

  const renderFamilyScreen = () => {
    if (page === 3) {
      return (
        <FamilyScreen
          previousPage={previousPage}
          onSubmit={nextPage}
          currentPage={page}
        />
      );
    }
  };

  const renderChildInfoScreen = () => {
    if (page === 4) {
      return (
        <ChildInfoScreen
          previousPage={previousPage}
          onSubmit={nextPage}
          currentPage={page}
        />
      );
    }
  };

  const renderChildBirthScreen = () => {
    if (page === 5) {
      return (
        <ChildBirthScreen
          previousPage={previousPage}
          onSubmit={nextPage}
          currentPage={page}
        />
      );
    }
  };

  const renderTermsAndConditionsScreen = () => {
    if (page === 6) {
      return (
        <TermsAndConditionsScreen
          previousPage={previousPage}
          onSubmit={onSubmit}
          currentPage={page}
          captchaValue={validateCaptcha}
        />
      );
    }
  };

  let screenHeader =
    page !== 1
      ? i18next.t(
          'userRegisterScreenActions:registerScreen:headerText.navigationHeader'
        )
      : i18next.t(
          'userRegisterScreenActions:registerScreen:headerText.welcome'
        );

  return (
    <Container className="noPadding">
      <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
        <LocalNavigationComponent
          header={screenHeader}
          previousPage={page !== 1 ? previousPage : navigateToHomeScreen}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LanguageSelector language={language} setLanguage={setLanguage} />
          {renderInfoScreen()}
          {renderCredentialsScreen()}
          {renderFamilyScreen()}
          {renderChildInfoScreen()}
          {renderChildBirthScreen()}
          {renderTermsAndConditionsScreen()}
        </div>
      </Col>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues(FORMS.REGISTER_FORM)(state),
});

export default connect(mapStateToProps, {
  registerUser,
  showInfoModal,
  navigateToHomeScreen,
  showErrorModal,
})(RegisterScreen);
